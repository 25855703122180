import { v4 as uuidv4 } from 'uuid';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    getAdminAccount: builder.query({
      query: () => ({
        url: '/userbalance?username=aquanow',
        method: 'GET',
      }),
      transformResponse: (res, meta, arg) => {
        const result = { username: 'Aqua Commission', key: uuidv4() };
        const coins = arg.coins || [];
        coins.forEach((coin) => {
          result[coin] = res[0][coin];
        });
        return [result];
      },
    }),
  }),
});

export const { useGetAdminAccountQuery } = dashboardApi;
