import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { notification } from 'antd';
import { LOCAL_STORAGE_PREFIX } from 'src/config';

export const saveToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, JSON.stringify(data));
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};

export const getFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error retrieving from local storage:', error);
    return null;
  }
};

export const persistedBaseQuery = (baseUrl) => async (args, api, extraOptions) => {
  const { endpoint: localStorageKey } = api;
  const useLocalStorageCache = extraOptions?.useLocalStorageCache || false;

  try {
    const result = await fetchBaseQuery({ baseUrl })(args, api, extraOptions);

    if (result.error) {
      throw result.error;
    }
    if (useLocalStorageCache && result.data) {
      saveToLocalStorage(localStorageKey, result.data);
    }
    return result;
  } catch (error) {
    if (useLocalStorageCache) {
      const fallbackData = getFromLocalStorage(localStorageKey);

      if (fallbackData) {
        notification.warning({
          message: error?.status ?? 'Error',
          description: error?.error ?? error?.message ?? '',
        });

        return { data: fallbackData };
      }
    }

    throw error;
  }
};
