import {
  faBuildingColumns,
  faCalendarCheck,
  faCamera,
  faCircleDollar,
  faGauge,
  faShieldCheck,
  faSliders,
  faUserGroup,
  faWallet,
  faNetworkWired,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBuildingColumns as faBuildingColumnsSolid,
  faCalendarCheck as faCalendarCheckSolid,
  faCamera as faCameraSolid,
  faCircleDollar as faCircleDollarSolid,
  faGauge as faGaugeSolid,
  faShieldCheck as faShieldCheckSolid,
  faSliders as faSlidersSolid,
  faUserGroup as faUserGroupSolid,
  faWallet as faWalletSolid,
  faNetworkWired as faNetworkWiredSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * ⚠️ Since we are not dynamically loading routing data, please make sure you match the `path` attribute
 * to the real folder structure.
 */
export const menuData = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <FontAwesomeIcon icon={faGauge} />,
    iconSolid: <FontAwesomeIcon icon={faGaugeSolid} />,
  },
  {
    name: 'Currencies',
    path: '/currencies',
    icon: <FontAwesomeIcon icon={faCircleDollar} />,
    iconSolid: <FontAwesomeIcon icon={faCircleDollarSolid} />,
    children: [
      { name: 'Non-Market Coins', path: '/currencies/nonMarketCoins' },
      { name: 'Product Configuration', path: '/currencies/whitelistAssetTiers' },
      { name: 'Price Controls', path: '/currencies/priceControls' },
    ],
  },
  {
    name: 'Snapshots',
    path: '/snapshots',
    icon: <FontAwesomeIcon icon={faCamera} />,
    iconSolid: <FontAwesomeIcon icon={faCameraSolid} />,
    children: [
      { name: 'Price Snapshot', path: '/snapshots/priceSnapshot' },
      { name: 'User Balance Snapshot', path: '/snapshots/userBalanceSnapshot' },
      { name: 'Snapshot Repository', path: '/snapshots/repository' },
    ],
  },
  {
    name: 'Suspended Items',
    path: '/actionableItems',
    icon: <FontAwesomeIcon icon={faCalendarCheck} />,
    iconSolid: <FontAwesomeIcon icon={faCalendarCheckSolid} />,
  },
  {
    name: 'Execution',
    path: '/execution',
    icon: <FontAwesomeIcon icon={faSliders} />,
    iconSolid: <FontAwesomeIcon icon={faSlidersSolid} />,
    children: [
      { name: 'Create Execution', path: '/execution/createExecution' },
      { name: 'Latest Executions', path: '/execution/latestExecutions' },
    ],
  },
  {
    name: 'User Accounts',
    path: '/accounts',
    icon: <FontAwesomeIcon icon={faUserGroup} />,
    iconSolid: <FontAwesomeIcon icon={faUserGroupSolid} />,
    children: [
      { name: 'Client Management', path: '/accounts/clientManagement', clickable: 'true' },
      { name: 'Client List', path: '/accounts/clientList' },
      { name: 'User Login History', path: '/accounts/userLoginHistory' },
      { name: 'Client Reports', path: '/accounts/onDemandReports' },
    ],
  },
  {
    name: 'Transaction',
    path: '/transaction',
    icon: <FontAwesomeIcon icon={faWallet} />,
    iconSolid: <FontAwesomeIcon icon={faWalletSolid} />,
    children: [
      { name: 'Create Transaction', path: '/transaction/createTransaction' },
      { name: 'Manage Addresses', path: '/transaction/manageAddresses' },
      { name: 'Settlements', path: '/transaction/settlements' },
      { name: 'Active Transactions', clickable: 'true', path: '/transaction/activeTransactions' },
      { name: 'Transaction History', path: '/transaction/transactionHistory' },
      { name: 'Payment Bills', path: '/transaction/paymentBills' },
      { name: 'Fiat Deposit Instructions', path: '/transaction/fiatDepositAddresses' },
    ],
  },
  {
    name: 'Compliance',
    path: '/compliance',
    icon: <FontAwesomeIcon icon={faShieldCheck} />,
    iconSolid: <FontAwesomeIcon icon={faShieldCheckSolid} />,
    children: [{ name: 'Suspended Transactions', path: '/compliance/suspendedTransactions' }],
  },
  {
    name: 'Settlements',
    path: '/settlements',
    icon: <FontAwesomeIcon icon={faBuildingColumns} />,
    iconSolid: <FontAwesomeIcon icon={faBuildingColumnsSolid} />,
    children: [
      { name: 'On-Demand Settlement', path: '/settlements/onDemandSettlement' },
      { name: 'Process Settlement', path: '/settlements/processSettlement' },
    ],
  },
  {
    name: 'Shared Services',
    path: '/sharedServices',
    icon: <FontAwesomeIcon icon={faNetworkWired} />,
    iconSolid: <FontAwesomeIcon icon={faNetworkWiredSolid} />,
    children: [
      { name: 'Create Global Transaction', path: '/sharedServices/createGlobalTransaction' },
      { name: 'Banking Services', path: '/sharedServices/bankingServices' },
      { name: 'Expose Vaults', path: '/sharedServices/exposeVaults' },
    ],
  },
];
