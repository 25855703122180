import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { removeUndefinedPropertiesNonMutating } from '@utils/removeUndefinedProperties';
import { api, apiBasepath } from 'src/config';
import { multiQueryStringParamBuilder, prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
const getFilterOrderCall = (params) => ({
  url: '/filterOrder',
  method: 'GET',
  // remove any instances of undefined properties in params object
  params: removeUndefinedPropertiesNonMutating(params),
});

export const executionApi = createApi({
  reducerPath: 'executionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: multiQueryStringParamBuilder,
  }),
  keepUnusedDataFor: 5,
  tagTypes: ['Prebook'],
  endpoints: (builder) => ({
    getFilteredOrders: builder.query({
      query: getFilterOrderCall,
    }),
    getFilteredSettlementOrders: builder.query({
      query: (params) => ({
        url: 'v2/filterSettlementOrder',
        method: 'GET',
        params,
      }),
    }),
    getPaginatedFilteredOrders: builder.mutation({
      query: getFilterOrderCall,
      transformResponse: (res) => ({
        ...res,
        // [null] detected only on invalid OrderId
        orders: res?.orders?.filter((order) => order !== null && order !== undefined) || [],
      }),
    }),
    getVariousOrders: builder.query({
      query: (params) => ({
        url: `/order`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, params) => {
        if (params.preBookOrders) {
          return ['Prebook'];
        }
        return [];
      },
    }),
    updateOrders: builder.mutation({
      query: (payload) => ({
        url: `/order`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { isPrebookOrder }) => {
        if (isPrebookOrder) {
          return ['Prebook'];
        }
        return [];
      },
    }),
    createExecutionOrder: builder.mutation({
      query: (payload) => ({
        url: `/order`,
        method: 'POST',
        body: payload,
      }),
    }),
    deleteTWAPOrder: builder.mutation({
      query: (payload) => ({
        url: `/order`,
        method: 'DELETE',
        body: payload,
      }),
    }),
    amendExecution: builder.mutation({
      query: (payload) => ({
        url: `/adminAmendExecution`,
        method: 'POST',
        body: payload,
      }),
    }),
    generatePostTradeReport: builder.mutation({
      query: (params) => ({
        url: `/postTrade`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useGetLatestTxQuery,
  useGetActiveTradeQuery,
  useGetPrebookOrdersQuery,
  useGetNewOrdersQuery,
  useGetChildOrdersQuery,
  useGetVariousOrdersQuery,
  useGetFilteredOrdersQuery,
  useGetPaginatedFilteredOrdersMutation,
  useUpdateFilteredOrdersMutation,
  useGetFilteredSettlementOrdersQuery,
  useUpdateOrdersMutation,
  useCreateExecutionOrderMutation,
  useDeleteTWAPOrderMutation,
  useAmendExecutionMutation,
  useGeneratePostTradeReportMutation,
  endpoints: executionApiEndpoints,
} = executionApi;
