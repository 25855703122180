import { GraphQLAuthMode } from '@aws-amplify/core/internals/utils';
import { getEnvVariable } from '@utils/getEnvVariable';
import { ResourcesConfig } from 'aws-amplify';
import { IApi, IDestinationAddress, RegionCodes, TEnvironment, TRegionsMap } from 'src/types';

const AQUANOW_HOSTNAME = process.env.AQUANOW_HOSTNAME || 'aquanow.io';
const AQUANOW_ME_HOSTNAME = process.env.AQUANOW_ME_HOSTNAME || 'aquanow.ae';
const env = process.env.NEXT_PUBLIC_APP_ENV as TEnvironment;

const REGION = getEnvVariable(
  process.env.NEXT_PUBLIC_COGNITO_REGION,
  'NEXT_PUBLIC_COGNITO_REGION',
) as RegionCodes;
const CLIENT_ID = getEnvVariable(
  process.env.NEXT_PUBLIC_COGNITO_WEB_CLIENT_ID,
  'NEXT_PUBLIC_COGNITO_WEB_CLIENT_ID',
);
const USER_POOL_ID = getEnvVariable(
  process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  'NEXT_PUBLIC_COGNITO_USER_POOL_ID',
);
const GRAPHQL_ENDPOINT = getEnvVariable(
  process.env.NEXT_PUBLIC_APPSYNC_GRAPHQL_ENDPOINT,
  'NEXT_PUBLIC_APPSYNC_GRAPHQL_ENDPOINT',
);
const AUTH_MODE = getEnvVariable(
  process.env.NEXT_PUBLIC_APPSYNC_AUTH_MODE,
  'NEXT_PUBLIC_APPSYNC_AUTH_MODE',
);

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: USER_POOL_ID,
      userPoolClientId: CLIENT_ID,
    },
  },
  API: {
    GraphQL: {
      endpoint: GRAPHQL_ENDPOINT,
      defaultAuthMode: AUTH_MODE as GraphQLAuthMode,
      region: REGION,
    },
  },
};

const apiBasepath = {
  services: '/services',
  users: '/users',
  payment: '/payment/admin',
  bankingServices: '/bankingServices',
  transaction: '/transaction',
  pendingReview: '/transaction/pendingReview',
};

const destinationAddress: IDestinationAddress = {};
const api: IApi = { endpoints: {} };

switch (env) {
  case 'UAE_DEV': {
    api.endpoints = {
      wss: `wss://market.dev.${AQUANOW_ME_HOSTNAME}:8081`,
      wssExecution: `wss://report.dev.${AQUANOW_ME_HOSTNAME}:8081`,
      market: `https://aquaservices-dev.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute.dev.${AQUANOW_ME_HOSTNAME}`,
      marketSource: `https://marketsource.dev.${AQUANOW_ME_HOSTNAME}`,
      admin: `https://adminapi.dev.${AQUANOW_ME_HOSTNAME}`,
      payment: `https://api.dev.${AQUANOW_ME_HOSTNAME}`,
      bankingServices: `https://banking-service.staging.aquaservices.${AQUANOW_HOSTNAME}`,
    };
    destinationAddress.external = {
      fireblocks: 'TNxjkrHfkHaLbFhFBXtfLAw4ZQcgRmq7ct',
    };
    break;
  }

  case 'DEV': {
    api.endpoints = {
      wss: `wss://market-dev.${AQUANOW_HOSTNAME}:8081`,
      wssExecution: `wss://report-dev.${AQUANOW_HOSTNAME}:8081`,
      market: `https://aquaservices-dev.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute-dev.${AQUANOW_HOSTNAME}`,
      marketSource: `https://marketsource-dev.${AQUANOW_HOSTNAME}`,
      admin: `https://adminapi-dev.${AQUANOW_HOSTNAME}`,
      payment: `https://api-dev.${AQUANOW_HOSTNAME}`,
      //(XXX) TODO JULIE - change this when dev is ready and add it to the other environments
      bankingServices: `https://banking-service.staging.aquaservices.${AQUANOW_HOSTNAME}`,
    };
    destinationAddress.external = {
      fireblocks: 'TNxjkrHfkHaLbFhFBXtfLAw4ZQcgRmq7ct',
    };
    break;
  }

  case 'UAE_STAGING': {
    api.endpoints = {
      wss: `wss://market.staging.${AQUANOW_ME_HOSTNAME}:8081`,
      wssExecution: `wss://report.staging.${AQUANOW_ME_HOSTNAME}:8081`,
      market: `https://aquaservices-staging.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute.staging.${AQUANOW_ME_HOSTNAME}`,
      marketSource: `https://marketsource.staging.${AQUANOW_ME_HOSTNAME}`,
      admin: `https://adminapi.staging.${AQUANOW_ME_HOSTNAME}`,
      payment: `https://api.staging.${AQUANOW_ME_HOSTNAME}`,
      bankingServices: `https://banking-service.cert.aquanow.com`,
    };
    destinationAddress.external = {
      fireblocks: 'TNxjkrHfkHaLbFhFBXtfLAw4ZQcgRmq7ct',
    };
    break;
  }

  case 'STAGING': {
    api.endpoints = {
      wss: `wss://api-staging.${AQUANOW_HOSTNAME}:8081`,
      wssExecution: `wss://report-staging.${AQUANOW_HOSTNAME}:8081`,
      market: `https://aquaservices-staging.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute-staging.${AQUANOW_HOSTNAME}`,
      marketSource: `https://marketsource-staging.${AQUANOW_HOSTNAME}`,
      admin: `https://adminapi-staging.${AQUANOW_HOSTNAME}`,
      payment: `https://api-staging.${AQUANOW_HOSTNAME}`,
      bankingServices: `https://banking-service.staging.aquaservices.${AQUANOW_HOSTNAME}`,
    };
    destinationAddress.external = {
      fireblocks: 'TNxjkrHfkHaLbFhFBXtfLAw4ZQcgRmq7ct',
    };
    break;
  }

  case 'UAE_PROD': {
    api.endpoints = {
      wss: `wss://api.${AQUANOW_ME_HOSTNAME}:8081`,
      wssExecution: `wss://report.${AQUANOW_ME_HOSTNAME}:8081`,
      market: `https://aquaservices.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute.${AQUANOW_ME_HOSTNAME}`,
      marketSource: `https://marketsource.${AQUANOW_ME_HOSTNAME}`,
      admin: `https://adminapi.${AQUANOW_ME_HOSTNAME}`,
      payment: `https://api.${AQUANOW_ME_HOSTNAME}`,
      bankingServices: `https://banking-service.aquanow.com`,
    };
    destinationAddress.external = {
      fireblocks: 'TR5Q2Z15AfXBSX4PY7d389sgbDM8JTTphU',
    };
    break;
  }

  case 'PROD': {
    api.endpoints = {
      wss: `wss://api.${AQUANOW_HOSTNAME}:8081`,
      wssExecution: `wss://report.${AQUANOW_HOSTNAME}:8081`,
      market: `https://aquaservices.${AQUANOW_HOSTNAME}`,
      restExecution: `https://execute.${AQUANOW_HOSTNAME}`,
      marketSource: `https://marketsource.${AQUANOW_HOSTNAME}`,
      admin: `https://adminapi.${AQUANOW_HOSTNAME}`,
      payment: `https://api.${AQUANOW_HOSTNAME}`,
      bankingServices: `https://banking-service.aquanow.com`,
    };
    destinationAddress.external = {
      fireblocks: 'TR5Q2Z15AfXBSX4PY7d389sgbDM8JTTphU',
    };
    break;
  }

  default:
}

const networkTypeLabel = {
  eth: 'ERC-20',
  tron: 'TRC-20',
  xlm: 'XLM',
  ftm: 'FTM',
  matic: 'MATIC',
};

const REGIONS: TRegionsMap = {
  [RegionCodes.UsEast1]: {
    name: 'CLTS Technologies Ltd',
    color: '#01579b',
  },
  [RegionCodes.MeCentral1]: {
    name: 'Aquanow ME FZE',
    color: '#311b92',
  },
};

let FEATURE_FLAG_ENV = '';
switch (env) {
  case 'DEV':
  case 'UAE_DEV':
    FEATURE_FLAG_ENV = 'development';
    break;
  case 'STAGING':
  case 'UAE_STAGING':
    FEATURE_FLAG_ENV = 'staging';
    break;
  case 'PROD':
  case 'UAE_PROD':
    FEATURE_FLAG_ENV = 'production';
    break;
  default:
}

const REFRESH_FEATURE_FLAG = 'aquaservice_bank_integration_refresh_function';
const LOCAL_STORAGE_PREFIX = `admin_${env}_${REGION}_`;

export {
  env,
  REGION,
  api,
  apiBasepath,
  destinationAddress,
  amplifyConfig,
  REGIONS,
  FEATURE_FLAG_ENV,
  networkTypeLabel,
  REFRESH_FEATURE_FLAG,
  LOCAL_STORAGE_PREFIX,
};
