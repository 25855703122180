import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { multiQueryStringParamBuilder, prepareAuthHeaders } from './helper';

const { users } = apiBasepath;

export const settlementApi = createApi({
  reducerPath: 'settlementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}`,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: multiQueryStringParamBuilder,
  }),
  tagTypes: ['SettlementBatch', 'SettlementBatchSummary'],
  endpoints: (builder) => ({
    getSettlementBatch: builder.query({
      query: (params) => {
        return {
          url: `${users}/settlementBatch`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['SettlementBatch'],
    }),
    generateSettlementBatch: builder.mutation({
      query: (payload) => ({
        url: `${users}/settlementBatch/create/generateBatch`,
        method: 'POST',
        body: payload,
      }),
      providesTags: ['SettlementBatch'],
    }),
    getSettlementBatchSummary: builder.query({
      query: (settlementId) => ({
        url: `${apiBasepath.users}/settlementBatch`,
        method: 'GET',
        params: {
          settlementId,
          summary: true,
        },
      }),
      providesTags: ['SettlementBatchSummary'],
    }),
    getSettlementOrderDetails: builder.query({
      query: (settlementId) => ({
        url: `${apiBasepath.users}/settlementBatch`,
        method: 'GET',
        params: {
          settlementId,
          orders: true,
        },
      }),
    }),
    updateTradesMatched: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/tradesMatched`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    cancelSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/cancel`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    confirmPaymentOnSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/confirmPayment`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch'],
    }),
    completePaymentOnSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/paymentComplete`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    processWithdrawals: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/create/transactions`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch'],
    }),
    sendEmailSettlementReport: builder.mutation({
      query: (payload) => ({
        url: `${users}/settlementCreateReport/sendEmail`,
        method: 'POST',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(settlementApi.util.invalidateTags(['SettlementBatch']));
        }, 2000); // wait for the settlements to update before refetching
      },
    }),
    previewSettlementBatch: builder.mutation({
      query: (payload) => ({
        url: `${users}/combinedSettlementBatch/preview`,
        method: 'POST',
        body: payload,
      }),
    }),
    combineSettlementBatch: builder.mutation({
      query: (payload) => ({
        url: `${users}/combinedSettlementBatch/create`,
        method: 'POST',
        body: payload,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(settlementApi.util.invalidateTags(['SettlementBatch']));
        }, 2000); // wait for the settlements to update before refetching
      },
    }),
  }),
});

export const {
  useLazyGetSettlementBatchSummaryQuery,
  useLazyGetSettlementOrderDetailsQuery,
  useProcessWithdrawalsMutation,
  useGetSettlementBatchQuery,
  useLazyGetSettlementBatchQuery,
  useGenerateSettlementBatchMutation,
  useCancelSettlementBatchMutation,
  useCompletePaymentOnSettlementBatchMutation,
  useConfirmPaymentOnSettlementBatchMutation,
  useUpdateTradesMatchedMutation,
  useSendEmailSettlementReportMutation,
  usePreviewSettlementBatchMutation,
  useCombineSettlementBatchMutation,
} = settlementApi;
