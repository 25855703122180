import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Badge,
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { menuData } from '../menuData';
import styles from './index.module.scss';

interface MenuItem {
  name: string;
  path: string;
  icon?: ReactNode;
  iconSolid?: ReactNode;
  children?: MenuItem[];
}

interface MenuProps {
  readonly item: MenuItem;
  readonly transactionComplianceCount: number;
  readonly actionItemsCount: number;
  readonly bankingServicesCount: number;
  readonly currentPath: string;
  readonly isChild?: boolean;
}

const SidebarMenu = ({
  transactionComplianceCount,
  actionItemsCount,
  bankingServicesCount,
}: {
  transactionComplianceCount: number;
  actionItemsCount: number;
  bankingServicesCount: number;
}) => {
  const router = useRouter();

  return menuData.map((item) => (
    <MenuItem
      key={item.path}
      item={item}
      transactionComplianceCount={transactionComplianceCount}
      actionItemsCount={actionItemsCount}
      bankingServicesCount={bankingServicesCount}
      currentPath={router.pathname}
    />
  ));
};

export default SidebarMenu;

function MenuItemIcon({
  icon,
  path,
  transactionComplianceCount,
  actionItemsCount,
}: {
  readonly icon: ReactNode;
  readonly path: string;
  readonly transactionComplianceCount: number;
  readonly actionItemsCount: number;
}) {
  let badgeContent = 0;
  if (path === '/compliance' && transactionComplianceCount > 0) {
    badgeContent = transactionComplianceCount;
  } else if (path === '/actionableItems' && actionItemsCount > 0) {
    badgeContent = actionItemsCount;
  }
  const shouldDisplayBadge = badgeContent > 0;

  return (
    <Box width={24} height={24} display="flex" alignItems="center">
      {shouldDisplayBadge ? (
        <Badge variant="standard" badgeContent={badgeContent} color="error">
          <ListItemIcon>{icon}</ListItemIcon>
        </Badge>
      ) : (
        <ListItemIcon>{icon}</ListItemIcon>
      )}
    </Box>
  );
}

function MenuItem({
  item,
  transactionComplianceCount,
  actionItemsCount,
  bankingServicesCount,
  currentPath,
  isChild,
}: MenuProps) {
  const { children = [], name, path, icon, iconSolid } = item;
  const isParent = children.length > 0;
  const isChildSelected = currentPath.includes(item.path);
  const isSelected = isChild ? isChildSelected : currentPath === item.path;
  const [isExpanded, setIsExpanded] = useState(isChildSelected);
  const listItemProps = {
    ...(isParent
      ? { onClick: () => setIsExpanded((prev) => !prev) }
      : {
          component: Link,
          href: path,
        }),
    selected: isSelected,
    sx: { paddingLeft: isChild ? '40px' : '16px' },
  };

  return (
    <>
      <ListItemButton {...listItemProps}>
        {!isChild && (
          <MenuItemIcon
            icon={isSelected ? iconSolid : icon}
            path={path}
            transactionComplianceCount={transactionComplianceCount}
            actionItemsCount={actionItemsCount}
          />
        )}
        <ListItemText primary={name} classes={{ root: styles.singleLevelListItemText }} />
        {isParent && (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItemButton>
      {children.length > 0 && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={styles.menuChildrenContainer}>
            {children.map((child) => (
              <Box key={child.path} display="flex" alignItems="center">
                {child.path === '/sharedServices/bankingServices' && (
                  <Badge
                    variant="standard"
                    badgeContent={bankingServicesCount}
                    color="error"
                    sx={{ transform: 'translate(32px, -8px)', zIndex: 1 }}
                  />
                )}
                <MenuItem
                  isChild
                  currentPath={currentPath}
                  item={child}
                  transactionComplianceCount={transactionComplianceCount}
                  actionItemsCount={actionItemsCount}
                  bankingServicesCount={bankingServicesCount}
                />
              </Box>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
