import { fetchAuthSession } from 'aws-amplify/auth';

export async function prepareAuthHeaders(headers) {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    const jwtToken = accessToken.toString();
    const { payload } = idToken;
    const apiKey = payload['custom:CLIENT_PORTAL_KEY'];

    headers.set('auth-token', jwtToken);
    headers.set('x-api-key', apiKey);
    headers.set('x-nonce', Date.now().toString());
    return headers;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function sortFxSpread(spread, service) {
  return spread
    .map((item) => ({ key: `${service}-${item.symbol}`, ...item }))
    .sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
}

export function transformNonMDDataQuery(res) {
  return Object.entries(res)
    .filter(([key]) => /[A-Z0-9]{2,}/.test(key))
    .map(([symbol, { name, fiat }]) => ({
      symbol,
      name: name,
      fiat: Object.keys(fiat),
    }));
}

// generate request url based on params object especially for array value
export function multiQueryStringParamBuilder(params) {
  let url = '';
  for (let key in params) {
    if (params[key] instanceof Array) {
      url += `&${key}=${params[key].join(`&${key}=`)}`;
    } else {
      url += `&${key}=${params[key]}`;
    }
  }
  return url.substring(1);
}

/**
 * @function queryingWallets
 * @description get the associated walletTypes
 * @param {Object} param
 * @param {string} param.symbol symbol to query
 * @param {string} param.addressType address type to query (ERC20, TRC20, or all)
 * @return {Array} an array of walletTypes
 */
export function queryingWallets({ symbol, addressType }) {
  let wallets;
  if (symbol === 'USDT') {
    if (addressType === 'ERC-20') {
      wallets = ['fireblocksUserVault', 'fireblocksLowFreq'];
    } else if (addressType === 'TRC-20') {
      wallets = ['lowFrequencyWallet', 'tmUserWallet'];
    } else if (addressType === 'All Address Types') {
      wallets = ['fireblocksUserVault', 'fireblocksLowFreq', 'lowFrequencyWallet', 'tmUserWallet'];
    }
  } else if (symbol === 'BTC') {
    wallets = ['fireblocksUserVault', 'fireblocksLowFreq', 'btcColdWallet', 'btcHotWallet'];
  } else {
    wallets = ['fireblocksUserVault', 'fireblocksLowFreq'];
  }
  return wallets;
}

/**
 * @function createFilterString
 * @param filterModel
 * @returns {Object} filters
 */
export const createFilterString = (filterModel) => {
  return filterModel?.items.reduce((acc, { field, value }) => {
    if (value) {
      acc[field] = acc[field] || [];
      acc[field].push(value);
    }
    return acc;
  }, {});
};
