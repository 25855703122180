import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getDateRangeWithDayBounds } from '@utils/dateTime';
import { api, apiBasepath } from 'src/config';
import {
  MappingCondition,
  MappingRule,
  RefreshModalForm,
} from '@modules/sharedServices/bankingServices/types';
import { createFilterString, prepareAuthHeaders } from '@services/api/helper';

export interface UpdateMappingRuleOrderRequestPayload {
  order: {
    priority: number;
    ruleId: string;
  }[];
}

export interface EditMappingRuleRequestPayload {
  mapping_rule_id: string;
  client_username: string;
  client_account_id: string;
  downstream_id: string;
  next_mapping_status: string;
  conditions: MappingCondition[];
}

export interface ApproveTransactionRequestPayload {
  internal_transaction_id: string;
  is_auto_approve: boolean;
  action_by: string;
}

export interface CreateOneOffTransactionRequestPayload {
  action_by: string;
  client_account_id: string;
  client_username: string;
  downstream_id: string;
  internal_transaction_id: string;
}

enum TagTypes {
  Transactions = 'Transactions',
  MappingRules = 'MappingRules',
  Banks = 'Banks',
  BanksStatuses = 'BanksStatuses',
}

const { users, transaction, pendingReview, bankingServices } = apiBasepath;

export const bankingServicesApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}${bankingServices}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: Object.values(TagTypes),
  endpoints: (builder) => ({
    getDownStreamData: builder.query({
      query: () => '/downStream',
    }),
    getTransactions: builder.query({
      query: ({ filterModel, paginationModel }) => {
        const { page, pageSize } = paginationModel;
        const baseUrl = `${transaction}/getTransaction?page=${page + 1}&per_page=${pageSize}`;
        const filters = createFilterString(filterModel);
        const filterString = filters ? `filter=${encodeURIComponent(JSON.stringify(filters))}` : '';

        return filterString ? `${baseUrl}&${filterString}` : baseUrl;
      },
      providesTags: [TagTypes.Transactions],
    }),
    createOneOffTransaction: builder.mutation<void, CreateOneOffTransactionRequestPayload>({
      query: (body) => ({
        url: `${pendingReview}/oneOff`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypes.Transactions],
    }),
    ignoreTransaction: builder.mutation({
      query: (body) => ({
        url: `${pendingReview}/ignore`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypes.Transactions],
    }),
    approveTransaction: builder.mutation<void, ApproveTransactionRequestPayload>({
      query: (body) => ({
        url: `${pendingReview}/process`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypes.Transactions],
    }),
    getMappingRules: builder.query({
      query: () => '/mappingRule',
      providesTags: [TagTypes.MappingRules],
      transformResponse: (response: MappingRule[]) =>
        response.map((rule) => {
          let memo_main = '';
          let destination_address = '';
          if (rule.conditions.length) {
            memo_main =
              rule.conditions.find((condition) => condition.field === 'memo_main')?.value ?? '';
            destination_address =
              rule.conditions.find((condition) => condition.field === 'destination_address')
                ?.value ?? '';
          }
          return {
            ...rule,
            memo_main,
            destination_address,
            platform: rule.downstream.platform,
          };
        }),
    }),
    createMappingRule: builder.mutation({
      query: (body) => ({
        url: '/mappingRule',
        method: 'POST',
        body,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(
            bankingServicesApi.endpoints.retriggerMappingRuleUpdates.initiate({}),
          ).unwrap();
        } catch (error) {
          console.error('Error creating mapping rule', error);
        }
      },
    }),
    deleteMappingRule: builder.mutation({
      query: (body) => ({
        url: '/mappingRule',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: [TagTypes.MappingRules],
    }),
    updateMappingRuleOrder: builder.mutation<void, UpdateMappingRuleOrderRequestPayload>({
      query: (body) => ({
        url: '/mappingRule/reOrder',
        method: 'POST',
        body,
      }),
      invalidatesTags: [TagTypes.MappingRules],
    }),
    retriggerMappingRuleUpdates: builder.mutation({
      query: () => ({
        url: `${transaction}/mapping/trigger`,
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        setTimeout(() => {
          dispatch(bankingServicesApi.util.invalidateTags([TagTypes.Transactions]));
        }, 2000); // wait for the new mapping to complete on the backend before refetching transactions
      },
    }),
    editMappingRule: builder.mutation<void, EditMappingRuleRequestPayload>({
      query: (body) => ({
        url: '/mappingRule',
        method: 'PUT',
        body,
      }),
      invalidatesTags: [TagTypes.Transactions, TagTypes.MappingRules],
    }),
    getAllBanks: builder.query({
      query: () => '/bank/all',
      providesTags: [TagTypes.Banks],
    }),
    getBanksLockoutStatuses: builder.query({
      query: () => '/bank/lockoutStatus',
      providesTags: [TagTypes.BanksStatuses],
    }),
    refreshBankTransactions: builder.mutation<void, RefreshModalForm>({
      query: (body) => {
        const { bankName, date } = body;
        const { startTime: fromDate, endTime: toDate } = getDateRangeWithDayBounds(date, true);

        return {
          url: '/bank/refreshBankTransaction',
          method: 'POST',
          body: {
            bankName,
            fromDate,
            toDate,
          },
        };
      },
    }),
    getActionableTransactionsCount: builder.query({
      query: () => '/transaction/count',
      providesTags: [TagTypes.Transactions],
    }),
    createManualTransaction: builder.mutation({
      query: (body) => ({
        url: `/transaction/manual`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetDownStreamDataQuery,
  useGetTransactionsQuery,
  useCreateMappingRuleMutation,
  useDeleteMappingRuleMutation,
  useUpdateMappingRuleOrderMutation,
  useRetriggerMappingRuleUpdatesMutation,
  useCreateOneOffTransactionMutation,
  useIgnoreTransactionMutation,
  useGetMappingRulesQuery,
  useEditMappingRuleMutation,
  useApproveTransactionMutation,
  useGetAllBanksQuery,
  useGetBanksLockoutStatusesQuery,
  useRefreshBankTransactionsMutation,
  useGetActionableTransactionsCountQuery,
  useCreateManualTransactionMutation,
} = bankingServicesApi;
