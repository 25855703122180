import { EMAIL_REGEX } from '@utils/regex';
import { notification } from 'antd';
import React from 'react';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter((item) => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every((item) => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path,
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some((route) => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}
/* eslint no-useless-escape:0 */
const reg = /https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]+)+\/?/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v || Number.isNaN(v)) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

export function errorNotification(error = {}, message = 'Something is wrong') {
  notification.error({
    message,
    description: error.message,
  });
}

/**
 * Camel to Title Case. Not for Number.
 * @param {string} camelCase
 */
export function camelToTitle(camelCase) {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

/**
 * Simulate a fetch or dispatch call (async/await).
 * Don't forget to add await before this function and async on the parent function
 * @param {Number} ms
 */
export function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * Sorter helper, mostly for Table column sorter function.
 * @param {string} a first data value to sort
 * @param {string} b
 */
export function sortAlphabetically(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function sortColumn(a, b) {
  if (a === undefined) {
    return 1;
  }

  if (b === undefined) {
    return -1;
  }

  if (a === b) {
    return 0;
  }

  return a < b ? -1 : 1;
}

/**
 * Sorter helper, for a specific Coin Ranking order from Ops Team preference.
 * @param allCoins all coins array
 * @param {array} userArr array of objects to sort
 */
export function sortCoinRankingOrder(allCoins, userArr) {
  const rankingArray = ['USD', 'CAD', 'EUR', 'USDT', 'USDC', 'BTC', 'ETH']; // Some hardcoding for now?
  userArr.sort((a, b) => {
    if (rankingArray.includes(a.symbol)) {
      if (rankingArray.includes(b.symbol))
        return rankingArray.indexOf(a.symbol) - rankingArray.indexOf(b.symbol);
      return -1;
    }
    return 1;
  });
  const { marketCoins, nonMarketCoins } = userArr.reduce(
    (acc, coin) => {
      if (allCoins.includes(coin.symbol)) {
        acc.marketCoins.push(coin);
      } else {
        acc.nonMarketCoins.push(coin);
      }
      return acc;
    },
    { marketCoins: [], nonMarketCoins: [] },
  );
  return [...marketCoins, ...nonMarketCoins];
}

/**
 * Visual punctuation helper for Product Configuration Tier
 * @param {string} str Product Configuration Tier Title
 */
export function getTierTitle(str) {
  return str.replace(/^\w/, (c) => c.toUpperCase());
}

export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email);
};
// removes trailing zeros after decimal
export function normalize(val) {
  return parseFloat(val) ?? val;
}

export const isLegacyUser = (username, userSettingsData) =>
  userSettingsData?.list?.some(
    (account) => !account.accountId && !account.isCompanyUser && account.username === username,
  );
