import { store } from '@app/store';
import { createApi } from '@reduxjs/toolkit/query/react';
import { persistedBaseQuery } from '@services/api/persistedBaseQuery';
import { sortAlphabetically } from '@utils/utils';
import { api } from 'src/config';

const { Parser } = require('json2csv');
const parser = new Parser({ quote: '' });

export const marketHistoricalApi = createApi({
  reducerPath: 'marketHistoricalApi',
  baseQuery: persistedBaseQuery(`${api.endpoints.market}`),
  refetchOnReconnect: true,
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getOpenPrice: builder.query({
      query: (params) => ({
        url: `/priceSnapshot`,
        method: 'GET',
        params,
      }),
      transformResponse: (res) => {
        return res.map((item) => {
          const { symbol, price, itemDateTime } = item;
          const date = new Date(itemDateTime);
          const formattedTime = date.toLocaleString();
          return {
            symbol,
            price,
            lastUpdated: formattedTime,
          };
        });
      },
    }),
    getAquanowPrices: builder.query({
      query: (params) => ({
        url: `/priceSnapshot`,
        method: 'GET',
        params,
      }),
      transformResponse: (res, _, params) => {
        return {
          reportType: 'Prices',
          source: 'Aquanow',
          date: params.startTime,
          frequency: 'Daily',
          data: parser.parse(
            res.map((item) => {
              const { symbol, price, itemDateTime } = item;
              const date = new Date(itemDateTime);
              const formattedTime = date.toLocaleString();
              return {
                symbol,
                price,
                lastUpdated: formattedTime,
              };
            }),
          ),
        };
      },
    }),
    getSymbolConfig: builder.query({
      query: (params = { symbol: 'ALL' }) => ({
        url: `/symbolconfig`,
        method: 'GET',
        params,
      }),
      extraOptions: { useLocalStorageCache: true },
      transformResponse(baseQueryReturnValue) {
        return Object.keys(baseQueryReturnValue).sort(sortAlphabetically);
      },
    }),
    getAssets: builder.query({
      query: (params = {}) => ({
        url: `/asset`,
        method: 'GET',
        params,
      }),
      extraOptions: { useLocalStorageCache: true },
      transformResponse: (baseQueryReturnValue) => {
        const sortedKeys = Object.keys(baseQueryReturnValue).sort(sortAlphabetically);

        const fiats = [];
        const coins = [];
        const erc20Coins = [];

        sortedKeys.forEach((key) => {
          const { type, fbAssetId } = baseQueryReturnValue[key];
          if (type === 'Fiat') {
            fiats.push(key);
          } else if (type === 'Crypto') {
            coins.push(key);
            if (fbAssetId?.length) {
              if (fbAssetId.some(({ networkType }) => networkType === 'ERC20')) {
                erc20Coins.push(key);
              }
            }
          }
        });

        return { fiats, coins, erc20Coins, allAssetsObj: baseQueryReturnValue };
      },
    }),
  }),
});

export const {
  useGetOpenPriceQuery,
  useGetAquanowPricesQuery,
  useGetSymbolConfigQuery,
  useGetAssetsQuery,
} = marketHistoricalApi;

// A workaround to get assets from the store for non-react instances
export function getAssets() {
  const state = store.getState();
  const selectAssets = marketHistoricalApi.endpoints.getAssets.select();
  const assets = selectAssets(state);
  return assets?.data || {};
}
